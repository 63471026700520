import React, { useEffect, useState } from "react"; // Don't forget to import useEffect and useState
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import About from "./components/About";
import Menu from "./components/Menu";
import Home from "./components/Home";
import Contact from "./components/Contact";
import CateringForm from './components/CateringForm';
import ThankYou from './components/ThankYou';

import { Route, Routes } from "react-router-dom";
import "./App.css"; // Import your global styles
import styled from "styled-components";


// Styled component for the message container
const MessageContainer = styled.div`
  text-align: center;
  padding: 2rem;
  font-size: 2rem;
  color: #fff;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${require("./assets/images/dishes.webp")});
  background-size: cover;
  background-position: center;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    padding: 1.5rem;
  }
`;

// Styled component for the message text
const MessageText = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
  max-width: 600px;
  text-align: center;

  span {
    display: block;
    color: #f1975b;
    font-weight: bold;
    font-size: 2rem;
  }

  p {
    margin-top: 1rem;
    font-size: 1.2rem;
    color: #f5e5c0;
  }
`;

// Styled component for the logo
const Logo = styled.img`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 120px;
  height: auto;
  cursor: pointer;

  @media screen and (max-width: 768px) {
    width: 90px;
  }
`;

const App: React.FC = () => {
  const [hasPaid, setHasPaid] = useState(true); // Simulate payment status

  useEffect(() => {
    // Simulate checking payment status (replace with actual logic)
    const paymentStatus = true; // Simulate unpaid status
    setHasPaid(paymentStatus);
  }, []);

  return (
    <div>
      {/* Show the logo in the top-left corner */}
      <Logo src={require("./assets/images/logo.png")} alt="Logo" />

      {/* Conditional Rendering for Payment Status */}
      {!hasPaid ? (
        <MessageContainer>
          <MessageText>
            <span>Website Unavailable</span>
            <p>Please contact restaurant directly!</p>
          </MessageText>
        </MessageContainer>
      ) : (
        <>
          <NavBar /> {/* Render NavBar */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/catering" element={<CateringForm />} />
            <Route path="/thankyou" element={<ThankYou />} />
          </Routes>
          <Footer /> {/* Render Footer */}
        </>
      )}
    </div>
  );
};

export default App;
